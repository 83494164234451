import type { FormKitTypeDefinition, FormKitNode } from '@formkit/core'
import {
  outer,
  inner,
  wrapper,
  label,
  help,
  messages,
  message,
  selectInput,
  option,
  optionSlot,
  optGroup,
  $if,
  options,
  $attrs,
  selects,
} from '@formkit/inputs'
import { inlineSvg } from '@forms/formkit/sections'

export const selectInputSchema: FormKitTypeDefinition = {
  schema: $attrs(
    {
      'data-floating-label': '$floatingLabel',
    },
    outer(
      wrapper(
        label('$label'),
        inner(
          selectInput(
            $if(
              '$slots.default',
              () => '$slots.default',
              optionSlot(
                $if(
                  '$option.group',
                  optGroup(optionSlot(option('$option.label'))),
                  option('$option.label')
                )
              )
            )
          ),
          inlineSvg('chevron-down', '$classes.icon')
        )
      ),
      help('$help'),
      messages(message('$message.value'))
    )
  ),
  type: 'input',
  props: ['options', 'placeholder', 'optionsLoader', 'floatingLabel'],
  forceTypeProp: 'select',
  features: [options, selects, addHandlers],
  schemaMemoKey: 'zqpwzl4kjdn',
}

function addHandlers(node: FormKitNode) {
  node.on('created', () => {
    node.context!.floatingLabel = node.props.floatingLabel

    // node.on('prop:floatingLabel', () => {
    //   node.context!.floatingLabel = node.props.floatingLabel
    // })
  })
}
