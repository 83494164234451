import { searchInputSchema } from '@forms/formkit/inputs/search.ts'
import { numberInputSchema } from '@forms/formkit/inputs/number.ts'
import { textareaInputSchema } from '@forms/formkit/inputs/textarea.ts'
import { checkboxInputSchema } from '@forms/formkit/inputs/checkbox.ts'
import { radioInputSchema } from '@forms/formkit/inputs/radio.ts'
import { selectInputSchema } from '@forms/formkit/inputs/select.ts'
import {
  textInputSchema,
  emailInputSchema,
  passwordInputSchema,
} from '@forms/formkit/inputs/text-inputs.ts'

export const inputs = {
  number: numberInputSchema,
  search: searchInputSchema,
  textarea: textareaInputSchema,
  text: textInputSchema,
  email: emailInputSchema,
  password: passwordInputSchema,
  checkbox: checkboxInputSchema,
  radio: radioInputSchema,
  select: selectInputSchema,
}
