import type { FormKitTypeDefinition, FormKitNode } from '@formkit/core'
import {
  outer,
  inner,
  wrapper,
  label,
  help,
  messages,
  message,
  icon,
  prefix,
  suffix,
  textInput,
  casts,
  createSection,
  $attrs,
  $if,
} from '@formkit/inputs'

const count = createSection('count', () => ({
  $el: 'div',
  attrs: {
    class: `$classes.charCount`,
  },
  children: [
    {
      if: `$slots.count`,
      then: `$slots.count`,
      else: {
        $el: 'p',
        children: '$characterCount',
      },
    },
  ],
}))

function createTextInputDefinition(
  forceTypeProp: 'text' | 'email' | 'password',
  schemaMemoKey: string
): FormKitTypeDefinition {
  return {
    schema: $attrs(
      {
        'data-floating-label': '$floatingLabel',
      },
      outer(
        wrapper(
          label('$label'),
          inner(
            icon('prefix', 'label'),
            prefix(),
            textInput(),
            suffix(),
            icon('suffix')
          )
        ),
        $if('$displayCharacterCount', count('$characterCount')),
        help('$help'),
        messages(message('$message.value'))
      )
    ),
    type: 'input',
    family: 'text',
    props: {
      charCount: {
        boolean: true,
        default: false,
      },
      floatingLabel: {
        boolean: true,
        default: false,
      },
    },
    forceTypeProp,
    features: [casts, addHandlers],
    schemaMemoKey,
  }
}

function addHandlers(node: FormKitNode) {
  node.on('created', () => {
    node.context!.floatingLabel = node.props.floatingLabel
    node.context!.displayCharacterCount = node.props.charCount
    node.context!.characterCount = '0'

    function updateCharacterCount() {
      if (!node.props.charCount) {
        return
      }

      const count = (node._value as string)?.length || 0
      const maxLength = node.context!.attrs.maxlength as number | undefined

      let remaining: number
      if (maxLength !== undefined) {
        remaining = maxLength - count
        node.context!.characterCount = remaining.toString()
      } else {
        remaining = count
        node.context!.characterCount = count.toString()
      }
    }

    node.on('input', () => {
      updateCharacterCount()
    })

    node.on('prop:charCount', () => {
      node.context!.displayCharacterCount = node.props.charCount
      updateCharacterCount()
    })

    node.on('attr:maxlength', () => {
      updateCharacterCount()
    })
  })
}

export const textInputSchema: FormKitTypeDefinition = createTextInputDefinition(
  'text',
  '96k8e005opm'
)
export const emailInputSchema: FormKitTypeDefinition =
  createTextInputDefinition('email', '55myfo06c8c')

export const passwordInputSchema: FormKitTypeDefinition =
  createTextInputDefinition('password', 'k2ivb3903es')
