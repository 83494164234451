import { bindings, defineFormKitConfig } from '@formkit/vue'
import { createI18nPlugin, de, es } from '@formkit/i18n'
import {
  createLibraryPlugin,
  form,
  group,
  submit,
  hidden,
} from '@formkit/inputs'
import { createProPlugin, dropdown, colorpicker } from '@formkit/pro'
import { generateClasses, createThemePlugin } from '@formkit/themes'
import { genesisIcons } from '@formkit/icons'
import { inputs } from '@forms/formkit/config/inputs.js'
import { validation } from '@forms/formkit/config/validation.js'
import theme from './theme.js'

const customMessages = {
  de: {
    ui: {
      remainingChars: 'Noch {remaining} Zeichen übrig.',
    },
  },
  es: {
    ui: {
      remainingChars: 'Todavía quedan {restantes} caracteres.',
    },
  },
}

const mergedDe = { ...de, ...customMessages.de }
const mergedEs = { ...es, ...customMessages.es }

const library = createLibraryPlugin({
  group,
  form,
  submit,
  hidden,
})

const i18n = createI18nPlugin({
  de: mergedDe,
  es: mergedEs,
})

const proPlugin = createProPlugin('fk-daf990e47d', {
  dropdown,
  colorpicker,
})

const icons = {
  ...genesisIcons,
}

const themePlugin = createThemePlugin('', icons)

export default defineFormKitConfig({
  autoImport: false,
  config: {
    classes: generateClasses(theme),
  },
  plugins: [library, validation, i18n, bindings, proPlugin, themePlugin],
  locales: { de: mergedDe, es: mergedEs },
  locale: 'de',
  inputs,
})
