import type { FormKitTypeDefinition, FormKitNode } from '@formkit/core'
import {
  outer,
  inner,
  wrapper,
  label,
  help,
  messages,
  message,
  prefix,
  suffix,
  icon,
  textareaInput,
  initialValue,
  createSection,
  $if,
  $attrs,
} from '@formkit/inputs'

const count = createSection('count', () => ({
  $el: 'div',
  attrs: {
    class: `$classes.charCount`,
  },
  children: [
    {
      if: `$slots.count`,
      then: `$slots.count`,
      else: {
        $el: 'p',
        children: '$characterCount',
      },
    },
  ],
}))

export const textareaInputSchema: FormKitTypeDefinition = {
  schema: $attrs(
    {
      'data-floating-label': '$floatingLabel',
    },
    outer(
      wrapper(
        label('$label'),
        inner(
          icon('prefix', 'label'),
          prefix(),
          textareaInput(),
          suffix(),
          icon('suffix')
        )
      ),
      $if('$displayCharacterCount', count('$characterCount')),
      help('$help'),
      messages(message('$message.value'))
    )
  ),
  type: 'input',
  props: {
    charCount: {
      boolean: true,
      default: false,
    },
    floatingLabel: {
      boolean: true,
      default: false,
    },
  },
  forceTypeProp: 'textarea',
  features: [initialValue, addHandlers],
  schemaMemoKey: 'nykflic29u',
}

function addHandlers(node: FormKitNode) {
  node.on('created', () => {
    node.context!.floatingLabel = node.props.floatingLabel
    node.context!.displayCharacterCount = node.props.charCount
    node.context!.characterCount = '0'

    function updateCharacterCount() {
      if (!node.props.charCount) {
        return
      }

      const count = (node._value as string)?.length || 0
      const maxLength = node.context!.attrs.maxlength as number | undefined

      let remaining: number
      if (maxLength !== undefined) {
        remaining = maxLength - count
        node.context!.characterCount = remaining.toString()
      } else {
        remaining = count
        node.context!.characterCount = count.toString()
      }
    }

    node.on('input', () => {
      updateCharacterCount()
    })

    node.on('prop:charCount', () => {
      node.context!.displayCharacterCount = node.props.charCount
      updateCharacterCount()
    })

    node.on('attr:maxlength', () => {
      updateCharacterCount()
    })
  })
}
