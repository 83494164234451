export default {
  global: {
    form: 'w-full',
    icon: 'formkit-icon',
    suffixIcon: 'formkit-suffix-icon',
    help: 'formkit-help',
    message: 'formkit-message',
    messages: 'formkit-messages',
    input: 'formkit-input',
    wrapper: 'formkit-wrapper',
    outer: 'group/form-input',
    option: 'group/form-option',
    label: 'formkit-label',
  },

  /**
   * family text, contains all text inputs like text, email, password etc.
   */
  'family:text': {
    wrapper: 'formkit-wrapper-family-text',
    inner: 'formkit-inner-family-text',
    input: 'formkit-input-family-text',
    label: 'formkit-label-family-text',
  },

  search: {
    wrapper: 'formkit-wrapper-search',
    inner: 'formkit-inner-search',
    input: 'formkit-input-search',
    icon: 'formkit-icon-search',
    button: 'formkit-button-search',
  },

  number: {
    buttonDecrement: 'formkit-number-button text-system-error',
    buttonIncrement: 'formkit-number-button text-system-success',
    inner: 'formkit-number-inner',
    input: 'formkit-number-input',
  },

  textarea: {
    wrapper: 'formkit-wrapper-textarea',
    inner: 'formkit-inner-textarea',
    input: 'formkit-input-textarea scrollbar-themed',
  },

  /**
   * family box, contains all checkbox, radio inputs
   */
  'family:box': {
    wrapper: 'formkit-wrapper-family-box',
    decorator: 'formkit-decorator-family-box',
    decoratorIcon: 'formkit-decorator-icon-family-box',
    input: 'formkit-input-family-box peer',
    label: 'formkit-label-family-box',
    inner: 'formkit-inner-family-box',
    options: 'formkit-options-family-box',
    option: 'formkit-option-family-box',
    fieldset: 'formkit-fieldset-family-box',
    legend: 'formkit-legend-family-box',
  },

  checkbox: {
    decorator: 'formkit-decorator-checkbox',
    decoratorIcon: 'formkit-decorator-icon-checkbox',
  },

  radio: {
    decorator: 'formkit-decorator-radio',
  },

  select: {
    wrapper: 'formkit-wrapper-select',
    inner: 'formkit-inner-select',
    input: 'formkit-input-select',
    label: 'formkit-label-select',
    icon: 'formkit-icon-select',
    option: 'formkit-option-select',
  },

  'family:dropdown': {
    // pointer-events-none for the label are crucial
    // to allow the dropdown button to be clickable
    label: '!w-auto pointer-events-none',
    wrapper: `h-14 w-full rounded relative`,
    dropdownWrapper: `$reset
      my-2 w-full shadow-lg rounded relative [&::-webkit-scrollbar]:hidden
      bg-neutral-light-2 dark:bg-neutral-dark-2 dark:text-light text-dark
    `,
    emptyMessageInner: `
      flex items-center justify-center text-sm p-2 text-center w-full text-dark
      [&>span]:mr-3 [&>span]:ml-0
    `,
    listbox: 'shadow-lg rounded overflow-hidden',
    listboxButton: 'flex w-12 self-stretch justify-center mx-auto',
    listitem: `
      pl-7 w-full relative
      hover:bleed-neutral-light-1/25 dark:hover:bleed-neutral-dark-1/25 bleed-bg py-1
      data-[is-active="true"]:aria-selected:text-primary aria-selected:text-primary
    `,
    loaderIcon: 'ml-auto',
    loadMoreInner: `
      flex items-center justify-center text-sm p-2 text-center
      w-full text-blue-500 formkit-loading:text-gray-500 cursor-pointer
      [&>span]:mr-3 [&>span]:ml-0
    `,
    selectionWrapper: 'w-full mt-4',
    selection: 'w-full',
    option: `
      px-3 py-1 w-full text-left text-dark dark:text-light
    `,
    placeholder: 'px-3 py-2.5 text-dark dark:text-light',
    selector: 'h-14 flex justify-between items-center input input-hover',
    selectedIcon: 'block absolute top-1/2 left-2 w-3 -translate-y-1/2',
    selectIcon:
      'flex box-content w-4 px-2 self-stretch grow-0 shrink-0 [&>svg]:w-[1em]',
  },

  colorpicker: {
    inner: 'relative inline-flex',
    label: '!text-sm !pt-1 !font-semibold',
    swatchPreview:
      'flex justify-start items-center px-3 mt-6 cursor-pointer outline-none',
    canvasSwatchPreviewWrapper: `
      relative before:rounded-sm before:z-[2]
      before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full
    `,
    canvasSwatchPreview: `
      rounded aspect-[2/1] !w-10 border border-neutral
    `,
    valueString: 'inline-block ml-2 mr-1 text-dark dark:text-light',
    panel: `
      flex flex-col max-w-[300px] p-2 rounded
      bg-white touch-manipulation absolute w-[100vw]
      top-full left-0 border shadow-xl z-10
      [@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:!fixed
      [@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:top-auto
      [@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:max-w-none
      [@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:bottom-0
      [@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:left-0
      [@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:rounded-none
    `,
    panelClose: `
      flex justify-end items-center mb-1 -mt-1 border-none bg-none
      border-b w-[calc(100%+1rem)] -ml-2 pt-0 pr-2 pb-1 pl-2
    `,
    closeIcon: `
      w-[2rem] aspect-square p-1 rounded-full border
      [&>svg]:w-full [&>svg]:aspect-square [&>svg]:max-w-none [&>svg]:max-h-none
    `,
    controlGroup: `grid [grid-template-areas:'a_a_a'_'b_c_e'_'b_d_e'] mb-2`,
    canvas: `block w-full`,
    canvasLS: `aspect-[2/1] cursor-pointer rounded-sm`,
    LS: `[grid-area:a] relative mb-2`,
    preview: `
      [grid-area:b] w-8 inline-flex relative rounded overflow-hidden
      aspect-square rounded-sm
      after:content-[''] after:absolute after:top-0 after:left-0 after:w-full after:h-full
      after:rounded-sm after:shadow-[inset_0_0_0_1px_rgba(0,0,0,0.2)]
    `,
    hue: `[grid-area:c] relative inline-flex h-3/4 ml-2`,
    alpha: `[grid-area:d] relative inline-flex h-3/4 ml-2`,
    eyeDropper: `[grid-area:e] p-1.5 ml-2 inline-flex self-center justify-self-center aspect-square rounded-sm border cursor-pointer content-center items-center`,
    eyeDropperIcon: `w-auto [&>svg]:w-5`,
    control: `absolute bg-white shadow-[0_0_0_2px_rgba(255,255,255,1),0_0_0_3px_rgba(0,0,0,0.2)] -translate-x-1/2 -translate-y-1/2 pointer-events-none data-[prevent-focus-style]:shadow-[0_0_0_2px_rgba(255,255,255,1),0_0_0_3px_rgba(0,0,0,0.2)] focus-visible:outline-none focus-visible:shadow-[0_0_0_2px_rgba(255,255,255,1),0_0_0_3px_rgba(0,0,0,0.2),0_0_0_4px_rgba(59,130,246,1),0_0_0_5px_rgba(0,0,0,1)]`,
    controlLS: `w-[10px] h-[10px] rounded-full`,
    controlHue: `w-[4px] h-[calc(100%-2px)] top-1/2 rounded-[1px]`,
    controlAlpha: `w-[4px] h-[calc(100%-2px)] top-1/2 rounded-[1px]`,
    formatField: `
      flex
      items-center
      justify-center
      grow
    `,
    colorInputGroup: `
      flex
      items-center
      justify-center
      grow
    `,
    fieldGroup: `
      flex
      flex-col
      items-center
      justify-center
      w-full
      mr-1
      [&>input]:p-1
      [&>input]:text-sm
      [&>input]:m-0
      [&>input]:grow
      [&>input]:shrink
      [&>input]:w-full
      [&>input]:border
      [&>input]:rounded-sm
      [&>input]:text-center
      [&>input]:appearance-none
      [&>input::-webkit-outer-spin-button]:appearance-none
      [&>input::-webkit-inner-spin-button]:appearance-none
      [&>input::-webkit-inner-spin-button]:m-0
      [&>input:focus]:outline-none
      [&>input:focus]:ring
      [&>input:focus]:ring-blue-500
      max-[431px]:[&>input]:text-base
    `,
    fieldLabel: `
      text-xs
      mt-1
      opacity-50
    `,
    formatSwitcher: `
      !hidden
      flex
      justify-end
      self-start
      mt-1
      uppercase
      shrink-0
      p-1
      rounded-sm
      select-none
    `,
    switchIcon: `
      [&>svg]:w-3
    `,
    swatches: `
      flex
      flex-wrap
      w[calc(100%+0.5rem)]
      -ml-1
      pt-2
      pb-2
      mt-2
      -mb-2
      border-t
      overflow-auto
      max-h-[200px]
      select-none
      first:-mt-2
      first:border-t-0
    `,
    swatchGroup: `
      flex
      flex-wrap
      w-full
      mb-2
      last:mb-0
    `,
    swatchGroupLabel: `
      block
      w-full
      text-sm
      opacity-50
    `,
    swatch: `
      relative
      w-full
      max-w-[calc((100%/10)-0.5rem)]
      aspect-square
      m-1
      cursor-pointer
      before:content-['']
      before:absolute
      before:top-0
      before:left-0
      before:w-full
      before:h-full
      before:rounded-sm
      before:shadow-[inset_0_0_0_1px_rgba(0,0,0,0.2)]
      before:pointer-events-none
      before:z-[2]
      data-[active='true']:after:content-['']
      data-[active='true']:after:block
      data-[active='true']:after:absolute
      data-[active='true']:after:w-1.5
      data-[active='true']:after:h-1.5
      data-[active='true']:after:top-1/2
      data-[active='true']:after:left-1/2
      data-[active='true']:after:pointer-events-none
      data-[active='true']:after:rounded-full
      data-[active='true']:after:-translate-x-1/2
      data-[active='true']:after:-translate-y-1/2
      data-[active='true']:after:bg-white
      data-[active='true']:after:z-[2]
      data-[active='true']:after:ring-1
      data-[active='true']:after:ring-[rgba(0,0,0,0.33)]
      [&>canvas]:block
      [&>canvas]:w-full
      [&>canvas]:aspect-square
      [&>canvas]:rounded-sm
      [&>canvas:focus-visible]:outline-none
      [&>canvas:focus-visible]:shadow-[0_0_0_2px_rgba(255,255,255,1),0_0_0_4px_rgba(59,130,246,1)]
    `,
  },

  file: {
    label: '!text-sm !pt-1 !font-semibold',
    fileItem: 'flex items-center text-neutral mb-1 last:mb-0',
    fileItemIcon: 'w-4 mr-2 shrink-0',
    fileList: 'shrink grow peer px-3 mt-6',
    fileName: 'break-all grow text-ellipsis',
    fileRemove:
      'relative z-[2] ml-auto text-[0px] hover:text-system-error pl-2',
    fileRemoveIcon: 'block text-base w-3 relative z-[2]',
    inner: 'relative cursor-pointer formkit-multiple:[&>button]:absolute',
    input:
      'cursor-pointer text-transparent absolute top-0 right-0 left-0 bottom-0 opacity-0 z-[2]',
    noFiles: `
      flex w-full items-center px-3 mt-6
    `,
    noFilesIcon: 'w-4 mr-2',
  },
}
